
import { Roles, Users } from "@/api";
import { Role, User, UsersWithPagination } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { PageTitle }
})
export default class UserRolesPage extends Vue {
  headersTitle = [{ icon: "mdi-account-lock", header: "Users" }];
  buttonsArray = [
    {
      text: "Create",
      icon: "",
      color: "gray",
      action: "create"
    }
  ];
  tab = 0;
  filters = {
    name: "",
    email: "",
    role: null
  };
  roles: Array<Role> = [];
  users: Array<User> = [];
  options = {};
  page = 1;
  limit = 10;
  usersWithPagination: UsersWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  headers = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Email",
      value: "email"
    },
    {
      text: "Roles",
      value: "roles"
    },
    {
      text: "Actions",
      value: "controls",
      sortable: false,
      width: "150px"
    }
  ];
  timeout = 0;
  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadUsersData(val.page);
  }
  @Watch("filters", { deep: true })
  async onFiltersChange() {
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(async () => {
      await this.loadUsersData(this.page);
    }, 500);
  }
  @Watch("tab")
  async onTabChange() {
    await this.loadUsersData();
    this.resetFilter();
  }
  resetFilter() {
    this.filters = {
      name: "",
      email: "",
      role: null
    };
  }
  async created() {
    try {
      this.roles = await Roles.list();
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async loadUsersData(page = 1) {
    try {
      const usersWithPagination = await Users.list(
        page,
        this.filters,
        Number(!this.tab),
        this.limit
      );
      this.users = usersWithPagination.data ? usersWithPagination.data : [];
      this.usersWithPagination = usersWithPagination;
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  async onEdit(id: number) {
    await this.$router.push({
      name: "EditUser",
      params: { id: id.toString() }
    });
  }

  async onCreate() {
    await this.$router.push({ name: "CreateUser" });
  }

  async onToggleActive(user: User) {
    if (user && user.id) {
      try {
        if (user.active) {
          await Users.deactivate(user.id);
        } else {
          await Users.activate(user.id);
        }
        this.$success(
          `<strong>Success!</strong> User ${user.name} has been ${
            user.active ? "deactivated" : "activated"
          }!`
        );
        this.resetFilter();
        // this.loadUsersData();
      } catch (e) {
        const err = e as any;
        const errors = JSON.parse(err.errors?.active || null);
        if (errors) {
          const courses = errors.courses
            ? `${
                errors.courses.length === 1 ? "COURSE:" : "COURSES:<br/>"
              }  <div style='margin-left: 16px'>${errors.courses.join(
                ",<br/>"
              )}</div>`
            : "";
          const audits = errors.audits
            ? `${
                errors.audits.length === 1 ? "AUDIT:" : "AUDITS:<br/>"
              }  <div style='margin-left: 16px'>${errors.audits.join(
                ",<br/>"
              )}</div>`
            : "";
          const onlineCourses = errors.online_courses
            ? `${
                errors.online_courses.length === 1
                  ? "ONLINE COURSE:"
                  : "ONLINE COURSES:<br/>"
              }  <div style='margin-left: 16px'>${errors.online_courses.join(
                ",<br/>"
              )}</div>`
            : "";
          const events = errors.events
            ? `${
                errors.events.length === 1 ? "EVENT" : "EVENTS"
              }: ${errors.events.join(", ")} <br/>`
            : "";

          this.$dialog.notify.error(
            `This user has active <br/>
          ${courses}${audits}${onlineCourses}${events}
          `,

            {
              position: "bottom-right",
              timeout: 20000
            }
          );
        } else {
          this.$dialog.notify.error(
            err.result?.message || "Something went wrong"
          );
        }
      }
    }
  }
}
